import React, { useState } from 'react';
import character from '../Media/Images/bg/game_manage_bg.jpg';
import girl from '../Media/Documentation/assets/img/girl.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../Media/Documentation/assets/img/logo_gameit.png'
const LoginNew = () => {
  const [pin, setPin] = useState('');
  console.log("pin", pin)
  const navigate = useNavigate()

  // Define a style object for the background
  const backgroundStyle = {
    backgroundImage: `url(${character})`, // Use template literals to inject your image path
    backgroundSize: 'cover', // Cover the entire page
    backgroundPosition: 'center', // Center the background image
    width: '100vw', // 100% of the viewport width
    height: '100vh', // 100% of the viewport height
    position: 'fixed', // Fix the background to cover the entire screen
    top: 0,
    left: 0,
    zIndex: -1 // Ensure the background is behind other content
  };




  const handlePinChange = (e) => {
    console.log("value", e.target.value)
    setPin(e.target.value);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      PIN: pin
    }

    console.log("data", data)
    try {
      // Replace 'your/api/endpoint' with the actual endpoint where you want to post the PIN
      const response = await axios.post('https://tsel.visiontrek.in/api/login', data);
      console.log('Response:', response.data);
      if (response.data.result == 'User Login Successfully') {
        toast.success
          ("User Login Successfully !");
        localStorage.setItem('PIN', pin);
        setTimeout(() => {
          navigate('/')
        }, 3000);


      }
    } catch (error) {
      console.error('Error posting the PIN:', error.response.data.message);
      setPin('')
      toast.error(error.response.data.message)
      // Handle error here, for example showing an error message
    }
  };

  return (
    <div style={{ backgroundImage: `url(${character})` }} className='h-screen'> {/* Ensure this div takes up the full screen height */}
      {/* Background div */}
      <div >


        <div className='flex container flex-row justify-center mx-auto py-[130px] md:py-10'>

          <a href="#" class="flex  flex-row px-1 items-center bg-transparent max-w-full rouded border border-purple-900 shadow-xl  shadow-purple-900 rounded-xl  md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <div>

              <img class="object-cover  block w-full rounded-t-lg h-60 md:h-auto md:w-40 md:rounded-none md:rounded-s-lg" src={girl} alt="" />
            </div>

            <div class="flex flex-col justify-between ">



              <h5 class=" md:text-4xl mt-3 font-bold tracking-tight text-2xl  text-center text-white dark:text-white">Selamat datang di </h5>
              <p className='text-[#FFE900] md:text-3xl  text-2xl text-center'>Indo PlayZone</p>
             
              <div className='mt-3 px-2'>
                <form className="max-w-sm mx-auto" onSubmit={handleSubmit} >
                <div className="mb-3">
                  <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-100 dark:text-white">Masukkan PIN Anda</label>
                  <input type="text"  value={pin} onChange={handlePinChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-blue-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="******"  />
              
                </div>
                
                  <div className='flex justify-center mb-3'>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Lanjut</button>
                  </div>
                </form>
              </div>
            </div>
          </a>

        </div>
        <ToastContainer />

      </div>



    </div>
  );
}

export default LoginNew;
