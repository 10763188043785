import React, { useState } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './Animation - 1708078510267.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const NewLogin = () => {
  const [pin, setPin] = useState('');
  console.log("pin", pin)
  const navigate=useNavigate()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data={
      PIN:pin
    }
    try {
      // Replace 'your/api/endpoint' with the actual endpoint where you want to post the PIN
      const response = await axios.post('https://tsel.visiontrek.in/api/login', data);
      console.log('Response:', response.data);
          if(response.data.result=='User Login Successfully'){
            toast.success
            ("User Login Successfully !");
            localStorage.setItem('PIN', pin);
            setTimeout(() => {
              navigate('/')
            }, 3000);
     
                
          }
    } catch (error) {
      console.error('Error posting the PIN:', error.response.data.message);
      setPin('')
      toast.error(error.response.data.message)
      // Handle error here, for example showing an error message
    }
  };

  return (
    <div className='bg-[#8E67F1] h-screen'>
      <div className='container flex justify-center py-[90px]'>
        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <div>
            <Lottie options={defaultOptions} height={300} width={300} className="px-5" />
          </div>
          <div className="flex flex-col justify-between leading-normal">
            <h5 className="text-2xl tracking-tight text-center text-[#8E67F1] font-bold dark:text-white">Welcome to </h5>
            <span className='text-[#FFE900] text-3xl font-bold text-center'>Indo Playzone</span>
            <div className='mt-8 px-2'>
              <form className="max-w-sm mx-auto" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="pin" className="block mb-2 text-sm font-medium text-gray-100 dark:text-white">Enter your PIN</label>
                  <input type="text" id="pin" name="pin" value={pin} onChange={handlePinChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="******" required />
              
                </div>
                <div className='flex justify-center mb-3'>
                  <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer/>
      </div>

    </div>
  );
}

export default NewLogin;
